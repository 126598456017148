import Header from "../../shared/Header";
import Nav from "../../shared/Nav";
import axios from "axios";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Products.css";
import { useGetCategoryHook } from "../../shared/API";

const AddProduct = () => {
  const _initialState = {
    name: "",
    image: "",
    price: "",
    description: "",
    disc_price: "",
    qty: "",
    offerQty: "",
    offerDisc: "",
    detail: "",
    categoryId: "",
    additionalData: {
      p1: "",
      p2: "",
      p3: "",
      p4: "",
      p5: "",
      p6: "",
    },
    productImages: [""],
  };

  const [deleteLoader, setDeleteLoader] = useState(false);

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(_initialState);

  const [imageUploads, setImageUploads] = useState([]);

  const [file, prodFile] = useState("");

  const { isLoading, data: originalData } = useGetCategoryHook();

  /**
   * The handleChange function updates the value of a specific property in the product object based on
   * the user input.
   * @param e - The parameter `e` is an event object that is passed to the `handleChange` function. It
   * represents the event that triggered the function, such as a change event on an input field.
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "name" ||
      name === "image" ||
      name === "price" ||
      name === "description" ||
      name === "disc_price" ||
      name === "qty" ||
      name === "offerQty" ||
      name === "offerDisc" ||
      name === "detail" ||
      name === "categoryId"
    ) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        additionalData: {
          ...prevProduct.additionalData,
          [name]: value,
        },
      }));
    }
  };

  /**
   * The `handleImage` function is an asynchronous function that handles the uploading of an image file
   * to the Cloudinary API.
   * @param e - The parameter `e` is an event object that is passed to the `handleImage` function. It is
   * typically triggered when an input element of type "file" changes, indicating that a file has been
   * selected for upload.
   */
  const handleImage = async (e) => {
    setDeleteLoader(true);
    const selectedFiles = e.target.files;
    if (!selectedFiles.length) return;

    setLoading(true);
    const newImageUrls = [];
    for (const file of selectedFiles) {
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "products");

      const dataFile = await fetch(
        "https://api.cloudinary.com/v1_1/ronaklala-games/image/upload",
        {
          method: "POST",
          body: data,
        }
      )
        .then((r) => r.json())
        .catch((err) => {
          toast.error("Internal Server Error", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        });
      if (dataFile.secure_url !== null) {
        newImageUrls.push(dataFile.secure_url);
      }
    }
    setDeleteLoader(false);
    setLoading(false);
    toast.success("Image Uploaded Successfully");
    // Update imageUploads state by combining existing and new URLs
    setImageUploads((prevImageUrls) => [...prevImageUrls, ...newImageUrls]);
  };

  const handleAddImageInput = () => {
    setProduct((prevProduct) => {
      const newImages = [...prevProduct.productImages, ""];
      return {
        ...prevProduct,
        productImages: newImages,
      };
    });
  };
  /**
   * The `handlePdf` function is used to handle the upload of a PDF file to Cloudinary and display a
   * success message if the upload is successful.
   * @param e - The parameter `e` is an event object that is passed to the `handlePdf` function. It is
   * typically triggered when a file is selected using an input element of type "file".
   */
  const handlePdf = async (e) => {
    setDeleteLoader(true);
    setLoading(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("upload_preset", "product_detail_pdf");

    const dataFile = await fetch(
      "https://api.cloudinary.com/v1_1/ronaklala-games/upload",
      {
        method: "POST",
        body: data,
      }
    )
      .then((r) => r.json())
      .catch((err) => {
        toast.error("Internal Server Error", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });
    if (dataFile.secure_url !== null) {
      toast.success("Image Uploaded Successfully", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      prodFile(dataFile.secure_url);
      setLoading(false);
      setDeleteLoader(false);
    }
  };

  /**
   * The handleSubmit function is used to handle form submission and send a POST request to add a new
   * product to the server.
   */
  const handleSubmit = () => {
    product.productImages = imageUploads;

    product.detail = file;
    if (
      product.name === "" ||
      product.qty === "" ||
      product.description === "" ||
      product.price === "" ||
      product.disc_price === "" ||
      product.additionalData.p1 === "" ||
      product.additionalData.p2 === "" ||
      product.additionalData.p3 === "" ||
      product.additionalData.p4 === "" ||
      product.additionalData.p5 === "" ||
      product.additionalData.p6 === "" ||
      product.productImages.length < 0 ||
      product.detail === ""
    ) {
      toast.error("Please Fill the Whole Form", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else {
      axios
        .post(
          "https://nj-automations-api.vercel.app/api/admin/addNewProduct",
          product
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success("Product Added Successfully", {
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            setInterval(() => {
              window.location.href = "/view-product";
            }, 1500);
          }
        })
        .catch((err) => {
          toast.error("Internal Server Error", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          console.log(err);
        });
    }
  };
  return (
    <>
      {deleteLoader ? (
        <>
          <div class="loader lds-ring white">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Nav />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">NJ Automations /</span>{" "}
                  Add Product
                </h4>

                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="mb-3 col-md-6">
                            <label className="form-label">
                              Product Name <span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="basic-default-fullname"
                              placeholder="John Doe"
                              name="name"
                              value={product.name}
                              required
                              onChange={handleChange}
                            />
                          </div>

                          <div className="mb-3 col-md-6">
                            <label className="form-label">
                              Product Quantity <span className="red">*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="basic-default-fullname"
                              placeholder="120"
                              name="qty"
                              value={product.qty}
                              required
                              onChange={handleChange}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="basic-default-fullname"
                            >
                              Description
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="description"
                              name="description"
                              value={product.description}
                              required
                              onChange={handleChange}
                            />
                          </div>

                          <div className="row">
                            <div className="mb-3 col-md-6">
                              <label className="form-label">
                                Discount Price <span className="red">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="149"
                                value={product.discount}
                                name="disc_price"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label className="form-label">
                                Original Price <span className="red">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="249"
                                name="price"
                                value={product.price}
                                required
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="mb-3 col-md-6">
                              <label className="form-label">
                                Discount Quantity of this product for the
                                discount offer <span className="red">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="10"
                                value={product.offerQty}
                                name="offerQty"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label className="form-label">
                                Discount Percentage of this product for the
                                discount offer<span className="red">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="5"
                                name="offerDisc"
                                value={product.offerDisc}
                                required
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            {isLoading ? (
                              <></>
                            ) : (
                              <>
                                <div className="mb-4 col-md-12">
                                  <label className="form-label">
                                    Product Category
                                    <span className="red">*</span>
                                  </label>
                                  <select
                                    className="form-control"
                                    name="categoryId"
                                    value={product.categoryId}
                                    onChange={handleChange}
                                  >
                                    <option selected>-------------</option>
                                    {originalData.map((c, i) => (
                                      <>
                                        <option value={c._id}>{c.name}</option>
                                      </>
                                    ))}
                                  </select>
                                </div>
                              </>
                            )}
                          </div>

                          <div className="mb-3 col-md-12">
                            <label className="form-label">Product Images</label>
                            {product.productImages.map((imageUrl, index) => (
                              <div key={index} className="mb-2">
                                <input
                                  type="file"
                                  className="form-control phone-mask"
                                  name={`image[${index}]`} // Dynamic name for each image input
                                  onChange={(e) => handleImage(e)}
                                />
                              </div>
                            ))}
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-primary"
                              onClick={handleAddImageInput}
                            >
                              Add More Image
                            </button>
                          </div>

                          <div className="mb-3 col-md-12">
                            <label className="form-label">
                              Product Pdf
                              <span className="red">*</span>
                            </label>
                            <input
                              type="file"
                              className="form-control phone-mask"
                              name="detail"
                              accept="application/pdf"
                              required
                              value={product.detail}
                              onChange={handlePdf}
                            />
                          </div>
                        </div>

                        <br />
                        <hr />
                        <br />
                        <h2>Additional Data Fields</h2>
                        <div className="row">
                          <div className="mb-4 col-md-6">
                            <label className="form-label">
                              Product Category
                              <span className="red">*</span>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              id="basic-default-fullname"
                              placeholder="Enter Data Here"
                              name="p1"
                              value={product.additionalData.p1}
                              required
                              onChange={handleChange}
                            />
                          </div>

                          <div className="mb-4 col-md-6">
                            <label className="form-label">
                              Product Category
                              <span className="red">*</span>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              id="basic-default-fullname"
                              placeholder="Enter Data here"
                              name="p2"
                              value={product.additionalData.p2}
                              required
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="mb-4 col-md-6">
                            <label className="form-label">
                              Product Category
                              <span className="red">*</span>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              id="basic-default-fullname"
                              placeholder="Enter Data here"
                              name="p3"
                              value={product.additionalData.p3}
                              required
                              onChange={handleChange}
                            />
                          </div>

                          <div className="mb-4 col-md-6">
                            <label className="form-label">
                              Product Category
                              <span className="red">*</span>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              id="basic-default-fullname"
                              placeholder="Enter Data here"
                              name="p4"
                              value={product.additionalData.p4}
                              required
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="mb-4 col-md-6">
                            <label className="form-label">
                              Product Category
                              <span className="red">*</span>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              id="basic-default-fullname"
                              placeholder="Enter Data here"
                              name="p5"
                              value={product.additionalData.p5}
                              required
                              onChange={handleChange}
                            />
                          </div>

                          <div className="mb-4 col-md-6">
                            <label className="form-label">
                              Product Category
                              <span className="red">*</span>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              id="basic-default-fullname"
                              placeholder="Enter Data here"
                              name="p6"
                              value={product.additionalData.p6}
                              required
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                        >
                          {loading ? (
                            <>
                              <div class="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </>
                          ) : (
                            <>Submit</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddProduct;

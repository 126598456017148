import React from "react";
import Nav from "../shared/Nav";
import Header from "../shared/Header";

import { ToastContainer } from "react-toastify";
import { useGetDashboardHook } from "../shared/API";

const Home = () => {
  const { isLoading, data } = useGetDashboardHook();

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Nav />
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="row">
                {isLoading ? (
                  <>
                    <div class="lds-ring purple">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-12 col-md-4 order-1">
                      <div className="row">
                        <div className="col-lg-4 col-md-12 col-6 mb-4">
                          <div className="card">
                            <div className="card-body">
                              <div className="card-title d-flex align-items-start justify-content-between">
                                <div className="avatar flex-shrink-0">
                                  <img
                                    src="../assets/img/icons/unicons/wallet-info.png"
                                    alt="Credit Card"
                                    className="rounded"
                                  />
                                </div>
                              </div>
                              <span>Total Users</span>
                              <h3 className="card-title text-nowrap mb-1">
                                {data.users}
                              </h3>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-12 col-6 mb-4">
                          <div className="card">
                            <div className="card-body">
                              <div className="card-title d-flex align-items-start justify-content-between">
                                <div className="avatar flex-shrink-0">
                                  <img
                                    src="../assets/img/icons/unicons/wallet-info.png"
                                    alt="Credit Card"
                                    className="rounded"
                                  />
                                </div>
                                <div className="dropdown">
                                  <button
                                    className="btn p-0"
                                    type="button"
                                    id="cardOpt6"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="bx bx-dots-vertical-rounded" />
                                  </button>
                                  <div
                                    className="dropdown-menu dropdown-menu-end"
                                    aria-labelledby="cardOpt6"
                                  >
                                    <a className="dropdown-item" href="#/">
                                      View More
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <span>Total Orders</span>
                              <h3 className="card-title text-nowrap mb-1">
                                {data.orders}
                              </h3>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-12 col-6 mb-4">
                          <div className="card">
                            <div className="card-body">
                              <div className="card-title d-flex align-items-start justify-content-between">
                                <div className="avatar flex-shrink-0">
                                  <img
                                    src="../assets/img/icons/unicons/wallet-info.png"
                                    alt="Credit Card"
                                    className="rounded"
                                  />
                                </div>
                                <div className="dropdown">
                                  <button
                                    className="btn p-0"
                                    type="button"
                                    id="cardOpt6"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="bx bx-dots-vertical-rounded" />
                                  </button>
                                  <div
                                    className="dropdown-menu dropdown-menu-end"
                                    aria-labelledby="cardOpt6"
                                  >
                                    <a className="dropdown-item" href="#/">
                                      View More
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <span>Total Products</span>
                              <h3 className="card-title text-nowrap mb-1">
                                {data.products}
                              </h3>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-12 col-6 mb-4">
                          <div className="card">
                            <div className="card-body">
                              <div className="card-title d-flex align-items-start justify-content-between">
                                <div className="avatar flex-shrink-0">
                                  <img
                                    src="../assets/img/icons/unicons/wallet-info.png"
                                    alt="Credit Card"
                                    className="rounded"
                                  />
                                </div>
                                <div className="dropdown">
                                  <button
                                    className="btn p-0"
                                    type="button"
                                    id="cardOpt6"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="bx bx-dots-vertical-rounded" />
                                  </button>
                                  <div
                                    className="dropdown-menu dropdown-menu-end"
                                    aria-labelledby="cardOpt6"
                                  >
                                    <a className="dropdown-item" href="#/">
                                      View More
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <span>Total Stock Remaining</span>
                              <h3 className="card-title text-nowrap mb-1">
                                {data.totalStock}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Home;

import React, { useState } from "react";
import Header from "../../shared/Header";
import Nav from "../../shared/Nav";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useGetCategoryHook } from "../../shared/API";

const ViewCategory = () => {
  const [input, setInput] = useState("");

  const { isLoading, data: originalData } = useGetCategoryHook();

  let filteredData = originalData || [];

  const handleSearch = (e) => {
    e.preventDefault();
    setInput(e.target.value);
  };

  if (input.length > 0) {
    const lowerInput = input.toLowerCase();
    filteredData = filteredData.filter((category) =>
      category.name.toLowerCase().includes(lowerInput)
    );
  }

  const addETrimToUrl = (url) => {
    // Define the pattern to match
    const pattern = /(upload\/)(v\d+)/;

    // Replace the pattern with 'upload/e_trim/v169...'
    const modifiedUrl = url.replace(pattern, "upload/e_trim/$2");

    return modifiedUrl;
  };

  const deleteCategory = (id) => {
    axios
      .get(
        "https://nj-automations-api.vercel.app/api/admin/deleteCategory/" + id
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Category Deleted Successfully");
          setInterval(() => {
            window.location.reload();
          }, 1000);
        }
      });
  };

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Nav />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                {isLoading ? (
                  <>
                    <div class="lds-ring purple">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </>
                ) : (
                  <>
                    <h4 className="fw-bold py-3 mb-4">
                      <span className="text-muted fw-light">
                        NJ Automations /
                      </span>{" "}
                      Categories
                    </h4>

                    <div className="navbar-nav align-items-center">
                      <div className="nav-item d-flex align-items-center w-100">
                        <i className="bx bx-search fs-4 lh-0" />
                        <input
                          type="text"
                          className="form-control border-0 shadow-none"
                          placeholder="Search For Category By Their Name"
                          aria-label="Search..."
                          onChange={handleSearch}
                        />
                      </div>
                    </div>

                    <br />
                    <div className="row mb-5">
                      {filteredData.length === 0 ? (
                        <>
                          <h1>No Category Found</h1>
                        </>
                      ) : (
                        <>
                          {filteredData.map((p, i) => (
                            <>
                              <div className="col-md-4 col-lg-4 mb-4">
                                <div className="card h-80">
                                  <div className="card-body">
                                    <img
                                      src={addETrimToUrl(p.image)}
                                      alt="items"
                                      style={{
                                        width: "100%",
                                        maxHeight: "200px",
                                        maxWidth: "100%",
                                        objectFit: "contain",
                                      }}
                                    />
                                    <br />
                                    <br />
                                    <h5 className="card-title">
                                      Category Name: {p.name}
                                    </h5>
                                    <h5 className="card-title">
                                      Category Is Active: {p.isActive}
                                    </h5>
                                    <a
                                      className="col-3"
                                      href={"/Category/Products/" + p._id}
                                    >
                                      <button className="btn btn-primary mt-2">
                                        View Products in Category
                                      </button>
                                    </a>
                                    &nbsp;
                                    <a className="col-3 " href="#/">
                                      <button
                                        className="btn btn-danger mt-2"
                                        onClick={() => {
                                          deleteCategory(p._id);
                                        }}
                                      >
                                        Delete Category
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ViewCategory;

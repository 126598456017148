import Header from "../../shared/Header";
import Nav from "../../shared/Nav";
import axios from "axios";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddCategory = () => {
  const _initialState = {
    name: "",
    image: "",
    isActive: "True",
  };

  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(_initialState);

  const [pimg, setPimg] = useState("");

  /**
   * The handleChange function updates the value of a specific property in the product object based on
   * the user input.
   * @param e - The parameter `e` is an event object that is passed to the `handleChange` function. It
   * represents the event that triggered the function, such as a change event on an input field.
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory((event) => {
      return {
        ...event,
        [name]: value,
      };
    });
    console.log(category);
  };

  /**
   * The `handleImage` function is an asynchronous function that handles the uploading of an image file
   * to the Cloudinary API.
   * @param e - The parameter `e` is an event object that is passed to the `handleImage` function. It is
   * typically triggered when an input element of type "file" changes, indicating that a file has been
   * selected for upload.
   */
  const handleImage = async (e) => {
    setLoading(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("upload_preset", "products");

    const dataFile = await fetch(
      "https://api.cloudinary.com/v1_1/ronaklala-games/image/upload",
      {
        method: "POST",
        body: data,
      }
    )
      .then((r) => r.json())
      .catch((err) => {
        toast.error("Internal Server Error", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });
    if (dataFile.secure_url !== null) {
      toast.success("Image Uploaded Successfully", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setPimg(dataFile.secure_url);
      setLoading(false);
    }
  };

  /**
   * The `handlePdf` function is used to handle the upload of a PDF file to Cloudinary and display a
   * success message if the upload is successful.
   * @param e - The parameter `e` is an event object that is passed to the `handlePdf` function. It is
   * typically triggered when a file is selected using an input element of type "file".
   */

  /**
   * The handleSubmit function is used to handle form submission and send a POST request to add a new
   * product to the server.
   */
  const handleSubmit = () => {
    console.log(category);
    category.image = pimg;
    if (
      category.name === "" ||
      category.image === "" ||
      category.isActive === ""
    ) {
      toast.error("Please Fill the Whole Form", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else {
      axios
        .post(
          "https://nj-automations-api.vercel.app/api/admin/addNewCategory",
          category
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success("Category Added Successfully", {
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            setInterval(() => {
              window.location.href = "/ProductCategory";
            }, 1500);
          }
        })
        .catch((err) => {
          toast.error("Internal Server Error", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        });
    }
  };
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Nav />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">NJ Automations /</span>{" "}
                  Add Product
                </h4>

                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="mb-3 col-md-12">
                            <label className="form-label">
                              Category Name <span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="basic-default-fullname"
                              placeholder="John Doe"
                              name="name"
                              value={category.name}
                              required
                              onChange={handleChange}
                            />
                          </div>

                          <div className="mb-3 col-md-12">
                            <label className="form-label">
                              Category Image
                              <span className="red">*</span>
                            </label>
                            <input
                              type="file"
                              className="form-control phone-mask"
                              name="image"
                              required
                              defaultValue={category.image}
                              onChange={handleImage}
                            />
                          </div>

                          <div className="mb-4 col-md-12">
                            <label className="form-label">
                              Category Is Active?
                              <span className="red">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="isActive"
                              defaultValue={category.isActive}
                              onChange={handleChange}
                            >
                              <option selected value="true">
                                True
                              </option>
                              <option value="false">False</option>
                            </select>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                        >
                          {loading ? (
                            <>
                              <div class="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </>
                          ) : (
                            <>Submit</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddCategory;

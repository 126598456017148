export const GetCustomersQuery = `query {
    getCustomers {
      _id
      count
      userDetails {
        name
        _id
        email
        phno
      }
    }
  }`;

export const GetUsersQuery = `
  query {
    getAllUsers {
      name
      email
      phno
      _id
    }
  }
  `;

export const GetAllOrdersQuery = `
  query {
    getAllOrders {
      _id
      productDetails {
        name
      }
      createdAt
      qty
      status
    }
  }
  `;

export const DashboardQuery = `
  query  {
    getDashboard {
      orders
      products
      users
      totalStock
    }
}
`;

export const GetProductsQuery = `
query {
  products {
    productImages
    _id
    name
    price
    disc_price
  }
}
`;

export const GetSingleOrderQuery = `query ExampleQuery($getOrderByIdId: ID!) {
    getOrderById(id: $getOrderByIdId) {
      _id
      status
      qty
      razorpay_id
      createdAt
      tracking_id
      productDetails {
        name
        _id
        price
        productImages
      }
      address
      uid
      phno
      userDetails {
        email
        name
        _id
        phno
      }
    }
}
`;

export const CategoryQuery = `query {
    getCategories {
      name
      image
      isActive
      _id
    }
  }`;

export const GetProductsInCategoryQuery = `
  query GetProductByCategory($categoryId: ID!) {
    getProductByCategory(categoryId: $categoryId) {
      _id
      name
      productImages
      qty
      description
      price
      disc_price
      offerQty
      offerDisc
      detail
      categoryId
    }
  }`;

export const GetSingleProductQuery = `
query GetSingleProduct($getSingleProductId: ID!) {
    getSingleProduct(id: $getSingleProductId) {
      product {
        _id
        name
        productImages
        qty
        description
        price
        disc_price
        offerQty
        offerDisc
        detail
        categoryId
        additionalData {
          p1
          p2
          p3
          p4
          p5
          p6
        }
      }
      relatedProducts {
        _id
        name
        price
        disc_price
        productImages
      }
    }
  }
`;

import React from "react";
import $ from "jquery";
import { House, Users, X } from "phosphor-react";

import "../App.css";
import { Link } from "react-router-dom";

const Header = () => {
  const menu = (argument) => {
    $("." + argument).toggleClass("open");
  };

  const menuToggle = () => {
    $("#layout-menu").removeClass("menu-open");
  };

  return (
    <>
      <aside
        id="layout-menu"
        className="layout-menu menu-vertical menu bg-menu-theme"
      >
        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
          <a className="nav-item nav- px-4 py-4 me-xl-4" href="#/">
            <X
              size={22}
              onClick={() => {
                menuToggle();
              }}
            />
          </a>
        </div>
        <div className="app-brand demo">
          <a href="/" className="app-brand-link">
            <span className="app-brand-text demo menu-text fw-bolder ms-0">
              NJ Automations
            </span>
          </a>
          <a
            href="#/"
            className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
          >
            <i className="bx bx-chevron-left bx-sm align-middle" />
          </a>
        </div>
        <div className="menu-inner-shadow" />
        <ul className="menu-inner py-1">
          <li className="menu-header small text-uppercase">
            <span className="menu-header-text">Menu Options</span>
          </li>
          <li className="menu-item">
            <Link to="/" className="menu-link">
              <House size={22} className="menu-icon" />
              <div data-i18n="Analytics">Dashboard</div>
            </Link>
          </li>
          {/* <li className="menu-item deals">
                <a
                  href="#/"
                  className="menu-link menu-toggle"
                  onClick={() => {
                    menu("deals");
                  }}
                >
                  <Users size={22} className="menu-icon" />
                  Deals
                </a>
                <ul className="menu-sub">
                  <li className="menu-item">
                    <Link to="/view-deals" className="menu-link">
                      <div data-i18n="Analytics">View Deals</div>
                    </Link>
                  </li>

                  <li className="menu-item">
                    <Link to="/add-deals" className="menu-link">
                      <div data-i18n="Analytics">Add Deals</div>
                    </Link>
                  </li>
                </ul>
              </li> */}

          <li className="menu-item tag">
            <a
              href="#/"
              className="menu-link menu-toggle"
              onClick={() => {
                menu("tag");
              }}
            >
              <Users size={22} className="menu-icon" />
              <div data-i18n="Authentications">Products</div>
            </a>
            <ul className="menu-sub">
              <li className="menu-item">
                <Link to="/add-product" className="menu-link">
                  <div data-i18n="Basic">Add Products</div>
                </Link>
              </li>

              <li className="menu-item">
                <a href="/view-product" className="menu-link">
                  <div data-i18n="Basic">View Products</div>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-item cust">
            <a
              href="#/"
              className="menu-link menu-toggle"
              onClick={() => {
                menu("cust");
              }}
            >
              <Users size={22} className="menu-icon" />
              <div data-i18n="Authentications">Customers</div>
            </a>
            <ul className="menu-sub">
              <li className="menu-item">
                <a href="/customers" className="menu-link">
                  <div data-i18n="Basic">View Customers</div>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-item orders">
            <a
              href="#/"
              className="menu-link menu-toggle"
              onClick={() => {
                menu("orders");
              }}
            >
              <Users size={22} className="menu-icon" />
              <div data-i18n="Authentications">Orders</div>
            </a>
            <ul className="menu-sub">
              <li className="menu-item">
                <Link to="/Orders" className="menu-link">
                  <div data-i18n="Basic">View Orders</div>
                </Link>
              </li>
            </ul>
          </li>

          <li className="menu-item cat">
            <a
              href="#/"
              className="menu-link menu-toggle"
              onClick={() => {
                menu("cat");
              }}
            >
              <Users size={22} className="menu-icon" />
              <div data-i18n="Authentications">Product Category</div>
            </a>
            <ul className="menu-sub">
              <li className="menu-item">
                <Link to="/ProductCategory" className="menu-link">
                  <div data-i18n="Basic">View Product Categories</div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/AddCategory" className="menu-link">
                  <div data-i18n="Basic">Add Product Categories</div>
                </Link>
              </li>
            </ul>
          </li>

          <li className="menu-item users">
            <a
              href="#/"
              className="menu-link menu-toggle"
              onClick={() => {
                menu("users");
              }}
            >
              <Users size={22} className="menu-icon" />
              <div data-i18n="Authentications">Users</div>
            </a>
            <ul className="menu-sub">
              <li className="menu-item">
                <Link to="/Users" className="menu-link">
                  <div data-i18n="Basic">View Users</div>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default Header;

import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import AddProduct from "./components/Products/AddProduct";
import ViewProduct from "./components/Products/ViewProduct";
import Customers from "./components/Customers/Customers";
import Users from "./components/Users/Users";
import Orders from "./components/Orders/Orders";
import SingleOrder from "./components/Orders/SingleOrder";
import UpdateSingleProduct from "./components/Products/UpdateSingleProduct";
import AddCategory from "./components/Category/AddCategory";
import ViewCategory from "./components/Category/ViewCategory";
import ViewProductsInCategory from "./components/Category/ViewProductsInCategory";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/add-product" element={<AddProduct />} />
      <Route path="/view-product" element={<ViewProduct />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/Users" element={<Users />} />
      <Route path="/order/:id" element={<SingleOrder />} />
      <Route path="/AddCategory" element={<AddCategory />} />
      <Route path="/ProductCategory" element={<ViewCategory />} />
      <Route path="/edit/:id" element={<UpdateSingleProduct />} />
      <Route
        path="/Category/Products/:id"
        element={<ViewProductsInCategory />}
      />
    </Routes>
  );
}

export default App;

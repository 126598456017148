import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../shared/Header";
import Nav from "../../shared/Nav";
import { useGetAllOrdersHook } from "../../shared/API";

const Orders = () => {
  const [filter, setFilter] = useState("");
  const [input, setInput] = useState("");

  const { isLoading, data: originalData } = useGetAllOrdersHook();

  useEffect(() => {
    setFilter("");
  }, []);

  let filteredData = originalData || [];

  const handleSearch = (e) => {
    e.preventDefault();
    setInput(e.target.value);
  };

  if (input.length > 0) {
    const lowerInput = input.toLowerCase();
    filteredData = filteredData.filter((order) =>
      order._id.toLowerCase().includes(lowerInput)
    );
  }

  const handleFilter = (status) => {
    setFilter(status);
  };

  if (filter.length > 0) {
    const statusFilter = filter.toLowerCase();
    filteredData = filteredData.filter((order) =>
      order.status.toLowerCase().includes(statusFilter)
    );
  }
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Nav />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                {isLoading ? (
                  <>
                    <div class="lds-ring purple">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </>
                ) : (
                  <>
                    <h4 className="fw-bold py-3 mb-4">
                      <span className="text-muted fw-light">
                        NJ Automations /
                      </span>{" "}
                      Orders
                    </h4>

                    <div className="navbar-nav align-items-center">
                      <div className="nav-item d-flex align-items-center w-100">
                        <i className="bx bx-search fs-4 lh-0" />
                        <input
                          type="text"
                          className="form-control border-0 shadow-none"
                          placeholder="Search For Orders By Order ID..."
                          aria-label="Search..."
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <br />
                    <h5>Filter Orders by their Status</h5>

                    <div className="navbar-nav align-items-center">
                      <div
                        className="nav-item d-flex align-items-center w-100"
                        style={{ flexWrap: "wrap" }}
                      >
                        <button
                          className="btn"
                          onClick={() => {
                            handleFilter("Ordered");
                          }}
                          style={{
                            background: "#7DFB5A",
                            border: "none",
                            color: "#fff",
                            marginTop: "5px",
                          }}
                        >
                          Ordered
                        </button>
                        &nbsp;&nbsp;
                        <button
                          className="btn"
                          onClick={() => {
                            handleFilter("Prepairing");
                          }}
                          style={{
                            background: "#56F5FF",
                            border: "none",
                            color: "#fff",
                            marginTop: "5px",
                          }}
                        >
                          Prepairing
                        </button>
                        &nbsp;&nbsp;
                        <button
                          className="btn"
                          onClick={() => {
                            handleFilter("Dispatched");
                          }}
                          style={{
                            background: "#FFBC56",
                            border: "none",
                            marginTop: "5px",
                            color: "#fff",
                          }}
                        >
                          Dispatched
                        </button>
                        &nbsp;&nbsp;
                        <button
                          className="btn"
                          onClick={() => {
                            handleFilter("Delivered");
                          }}
                          style={{
                            background: "#9358FA",
                            marginTop: "5px",
                            border: "none",
                            color: "#fff",
                          }}
                        >
                          Delivered
                        </button>
                        &nbsp;&nbsp;
                        <button
                          className="btn"
                          onClick={() => {
                            handleFilter("");
                          }}
                          style={{
                            background: "#FF6041",
                            border: "none",
                            marginTop: "5px",
                            color: "#fff",
                          }}
                        >
                          Remove Filters
                        </button>
                      </div>
                    </div>

                    <br />

                    <div className="row mb-5">
                      {filteredData.length === 0 ? (
                        <>
                          <h1>No One has placed Any Order Yet</h1>
                        </>
                      ) : (
                        <>
                          {filteredData.map((p, i) => (
                            <>
                              <div className="col-md-4 col-lg-4 mb-4">
                                <div className="card h-80">
                                  <div className="card-body">
                                    <h6 className="card-title">
                                      Order Id: {p._id}
                                    </h6>
                                    {p.productDetails.map((pd, i) => (
                                      <>
                                        {" "}
                                        <h6
                                          className="card-title"
                                          style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: "2",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          Product Name: {pd.name}
                                        </h6>
                                      </>
                                    ))}

                                    <h6 className="card-title">
                                      Order Date: {p.createdAt}
                                    </h6>
                                    <h6 className="card-title">
                                      Quantity: {p.qty}
                                    </h6>

                                    <h6 className="card-title">
                                      Status: {p.status}
                                    </h6>
                                    <a href={"/order/" + p._id}>
                                      <button className="btn btn-primary">
                                        View in Detail
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Orders;

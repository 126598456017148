import axios from "axios";
import { useQuery } from "react-query";
import {
  CategoryQuery,
  DashboardQuery,
  GetAllOrdersQuery,
  GetCustomersQuery,
  GetProductsInCategoryQuery,
  GetProductsQuery,
  GetSingleOrderQuery,
  GetSingleProductQuery,
  GetUsersQuery,
} from "./Queries";

export const useGetCustomersHook = () => {
  return useQuery("customers", async () => {
    try {
      const response = await axios.post(
        "https://nj-automations-api.vercel.app/graphql",
        {
          query: GetCustomersQuery,
        }
      );

      return response.data.data.getCustomers;
    } catch (err) {
      console.log("Internal Server Error");
    }
  });
};

export const useGetUserHook = () => {
  return useQuery("users", async () => {
    try {
      const response = await axios.post(
        "https://nj-automations-api.vercel.app/graphql",
        {
          query: GetUsersQuery,
        }
      );

      return response.data.data.getAllUsers;
    } catch (err) {
      console.log("Internal Server Error");
    }
  });
};

export const useGetAllOrdersHook = () => {
  return useQuery("AllOrders", async () => {
    try {
      const response = await axios.post(
        "https://nj-automations-api.vercel.app/graphql",
        {
          query: GetAllOrdersQuery,
        }
      );
      return response.data.data.getAllOrders;
    } catch (err) {
      throw new Error("Failed to fetch orders");
    }
  });
};

export const useGetDashboardHook = () => {
  return useQuery("dashboard", async () => {
    try {
      const response = await axios.post(
        "https://nj-automations-api.vercel.app/graphql",
        {
          query: DashboardQuery,
        }
      );
      return response.data.data.getDashboard;
    } catch (err) {
      throw new Error("Failed to fetch Dashboard data");
    }
  });
};

export const useGetProductsHook = () => {
  return useQuery("products", async () => {
    try {
      const response = await axios.post(
        "https://nj-automations-api.vercel.app/graphql",
        {
          query: GetProductsQuery,
        }
      );

      return response.data.data.products;
    } catch (error) {
      throw new Error("Failed to fetch products");
    }
  });
};

export const useGetSingleOrderHook = (id) => {
  return useQuery("SingleOrder", async () => {
    try {
      const singleOrderDetails = await axios.post(
        "https://nj-automations-api.vercel.app/graphql",
        {
          query: GetSingleOrderQuery,
          variables: { getOrderByIdId: id },
        }
      );

      return singleOrderDetails.data.data.getOrderById;
    } catch (err) {
      throw new Error("Failed to Fetch Order");
    }
  });
};

export const useGetCategoryHook = () => {
  return useQuery("getCategories", async () => {
    try {
      const categories = await axios.post(
        "https://nj-automations-api.vercel.app/graphql",
        {
          query: CategoryQuery,
        }
      );

      return categories.data.data.getCategories;
    } catch (err) {
      console.log("Internal Server Error");
    }
  });
};

export const useGetProductsInCategoryHook = (id) => {
  return useQuery("CategoryProducts", async () => {
    try {
      const products = await axios.post(
        "https://nj-automations-api.vercel.app/graphql",
        {
          query: GetProductsInCategoryQuery,
          variables: { categoryId: id },
        }
      );

      return products.data.data.getProductByCategory;
    } catch (err) {
      throw new Error("Failed to Fetch Products");
    }
  });
};

export const useGetSingleProduct = (id) => {
  return useQuery("SingleProduct", async () => {
    try {
      const response = await axios.post(
        "https://nj-automations-api.vercel.app/graphql",
        {
          query: GetSingleProductQuery,
          variables: { getSingleProductId: id },
        }
      );

      if (response.data.errors && response.data.errors.length > 0) {
        window.location.href = "/NotFound";
      }

      return response.data.data.getSingleProduct; // Assuming successful response
    } catch (error) {
      console.error("Error fetching product:", error);
      throw error; // Re-throw for handling in your component
    }
  });
};

import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../shared/Header";
import Nav from "../../shared/Nav";
import { useGetCustomersHook } from "../../shared/API";

const Customers = () => {
  let [input, setInput] = useState("");

  const { isLoading, isError, data: originalData } = useGetCustomersHook();

  const inputIsEmpty = input.length === 0;
  const lowerInput = input.toLowerCase();

  const filteredData = inputIsEmpty
    ? originalData || []
    : originalData.filter((customer) =>
        customer._id.toLowerCase().includes(lowerInput)
      ) || [];

  if (isError) {
    toast.error("Internal Server Error");
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setInput(e.target.value);
  };
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Nav />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                {isLoading ? (
                  <>
                    <div class="lds-ring purple">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </>
                ) : (
                  <>
                    <h4 className="fw-bold py-3 mb-4">
                      <span className="text-muted fw-light">
                        NJ Automations /
                      </span>{" "}
                      Customers
                    </h4>

                    <div className="navbar-nav align-items-center">
                      <div className="nav-item d-flex align-items-center w-100">
                        <i className="bx bx-search fs-4 lh-0" />
                        <input
                          type="text"
                          className="form-control border-0 shadow-none"
                          placeholder="Search For Customers By User ID..."
                          aria-label="Search..."
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <br />

                    <div className="row mb-5">
                      {filteredData.length === 0 ? (
                        <>
                          <h1>No Customers Found Yet</h1>
                        </>
                      ) : (
                        <>
                          {filteredData.map((p, i) => (
                            <>
                              <div className="col-md-4 col-lg-4 mb-4">
                                <div className="card h-80">
                                  <div className="card-body">
                                    <h6 className="card-title">
                                      Name: {p.userDetails.name}
                                    </h6>
                                    <h6 className="card-title">
                                      Email: {p.userDetails.email}
                                    </h6>
                                    <h6 className="card-title">
                                      Phone Number: {p.userDetails.phno}
                                    </h6>

                                    <h6 className="card-title">
                                      Customer ID: {p.userDetails._id}
                                    </h6>
                                    <h6 className="card-title">
                                      Number of Orders: {p.count}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Customers;

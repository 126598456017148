import Header from "../../shared/Header";
import Nav from "../../shared/Nav";
import axios from "axios";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Products.css";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useGetCategoryHook } from "../../shared/API";
import { GetSingleProductQuery } from "../../shared/Queries";
const UpdateSingleProduct = () => {
  const addETrimToUrl = (url) => {
    // Define the pattern to match
    const pattern = /(upload\/)(v\d+)/;

    // Replace the pattern with 'upload/e_trim/v169...'
    const modifiedUrl = url.replace(pattern, "upload/e_trim/$2");

    return modifiedUrl;
  };

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({
    additionalData: {
      p1: "",
      p2: "",
      p3: "",
      p4: "",
      p5: "",
      p6: "",
    },
  });

  const params = useParams();

  const { isLoading: productLoading } = useQuery("SingleProduct", async () => {
    try {
      const response = await axios.post(
        "https://nj-automations-api.vercel.app/graphql",
        {
          query: GetSingleProductQuery,
          variables: { getSingleProductId: params.id },
        }
      );

      if (response.data.errors && response.data.errors.length > 0) {
        window.location.href = "/NotFound";
      }

      setProduct(response.data.data.getSingleProduct.product); // Assuming successful response
    } catch (error) {
      console.error("Error fetching product:", error);
      throw error; // Re-throw for handling in your component
    }
  });

  const [file, prodFile] = useState("");

  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it is
 used to fetch data from an API endpoint when the component mounts or when the `params.id` value
 changes. */

  /**
   * The handleChange function updates the value of a specific property in the product object based on
   * the user input.
   * @param e - The parameter `e` is an event object that is passed to the `handleChange` function. It
   * represents the event that triggered the function, such as a change event on an input field.
   */

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "name" ||
      name === "image" ||
      name === "price" ||
      name === "description" ||
      name === "disc_price" ||
      name === "qty" ||
      name === "offerQty" ||
      name === "offerDisc" ||
      name === "detail" ||
      name === "categoryId"
    ) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        additionalData: {
          ...prevProduct.additionalData,
          [name]: value,
        },
      }));
    }
  };

  const { isLoading, data: originalData } = useGetCategoryHook();
  /**
   * The function `handleImage` is an asynchronous function that handles the uploading of an image file
   * to the Cloudinary API.
   * @param e - The parameter `e` is an event object that is passed to the `handleImage` function. It is
   * typically triggered when an input element of type "file" changes, indicating that a file has been
   * selected for upload.
   */
  // const handleImage = async (e) => {
  //   setLoading(true);
  //   const data = new FormData();
  //   data.append("file", e.target.files[0]);
  //   data.append("upload_preset", "products");

  //   const dataFile = await fetch(
  //     "https://api.cloudinary.com/v1_1/ronaklala-games/image/upload",
  //     {
  //       method: "POST",
  //       body: data,
  //     }
  //   )
  //     .then((r) => r.json())
  //     .catch((err) => {
  //       toast.error("Internal Server Error", {
  //         style: {
  //           borderRadius: "10px",
  //           background: "#333",
  //           color: "#fff",
  //         },
  //       });
  //     });
  //   if (dataFile.secure_url !== null) {
  //     toast.success("Image Uploaded Successfully", {
  //       style: {
  //         borderRadius: "10px",
  //         background: "#333",
  //         color: "#fff",
  //       },
  //     });
  //     setPimg(dataFile.secure_url);
  //     setLoading(false);
  //   }
  // };

  const handlePdf = async (e) => {
    setLoading(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("upload_preset", "product_detail_pdf");

    const dataFile = await fetch(
      "https://api.cloudinary.com/v1_1/ronaklala-games/upload",
      {
        method: "POST",
        body: data,
      }
    )
      .then((r) => r.json())
      .catch((err) => {
        toast.error("Internal Server Error", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      });
    if (dataFile.secure_url !== null) {
      toast.success("Image Uploaded Successfully", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      prodFile(dataFile.secure_url);
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (file !== "") {
      product.detail = file;
    }
    /* The commented code block is checking if all the fields in the form (product name, image,
     quantity, description, price, and discount price) are empty. If any of these fields are empty, it
     displays an error message using the `toast.error()` function. */
    if (
      product.name === "" &&
      product.image === "" &&
      product.qty === "" &&
      product.description === "" &&
      product.price === "" &&
      product.disc_price === ""
    ) {
      toast.error("Please Fill the Whole Form", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } /* This code block is responsible for updating the product information in the database. It makes
      a POST request to the specified API endpoint with the updated product data. If the request is
      successful (status code 200), it displays a success message using the `toast.success()`
      function and redirects the user to the "/view-product" page after a delay of 1500
      milliseconds. If there is an error during the request, it displays an error message using the
      `toast.error()` function. */ else {
      axios
        .post(
          "https://nj-automations-api.vercel.app/api/admin/updateProduct/" +
            product._id,
          product
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success("Product Updated Successfully", {
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            setInterval(() => {
              window.location.href = "/view-product";
            }, 1500);
          }
        })
        .catch((err) => {
          toast.error("Internal Server Error", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        });
    }
  };

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Nav />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                  <span className="text-muted fw-light">NJ Automations /</span>{" "}
                  Update Product
                </h4>
                {productLoading ? (
                  <>
                    {" "}
                    <div class="lds-ring purple">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-xl">
                        <div className="card mb-4">
                          <div className="card-body">
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label className="form-label">
                                  Product Name <span className="red">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="basic-default-fullname"
                                  placeholder="John Doe"
                                  name="name"
                                  value={product.name}
                                  required
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="mb-3 col-md-6">
                                <label className="form-label">
                                  Product Quantity{" "}
                                  <span className="red">*</span>
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="basic-default-fullname"
                                  placeholder="120"
                                  name="qty"
                                  value={product.qty}
                                  required
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="basic-default-fullname"
                                >
                                  Description
                                </label>
                                <textarea
                                  className="form-control"
                                  placeholder="description"
                                  name="description"
                                  value={product.description}
                                  required
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="row">
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">
                                    Discount Quantity of this product for the
                                    discount offer{" "}
                                    <span className="red">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="10"
                                    value={product.offerQty}
                                    name="offerQty"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">
                                    Discount Percentage of this product for the
                                    discount offer<span className="red">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="5"
                                    name="offerDisc"
                                    value={product.offerDisc}
                                    required
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">
                                    Discount Price{" "}
                                    <span className="red">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="149"
                                    value={product.disc_price}
                                    name="disc_price"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">
                                    Original Price{" "}
                                    <span className="red">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="249"
                                    name="price"
                                    value={product.price}
                                    required
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className="row">
                                {isLoading ? (
                                  <></>
                                ) : (
                                  <>
                                    <div className="mb-4 col-md-12">
                                      <label className="form-label">
                                        Product Category
                                        <span className="red">*</span>
                                      </label>
                                      <select
                                        className="form-control"
                                        name="categoryId"
                                        defaultValue={product.categoryId}
                                        onChange={handleChange}
                                      >
                                        <option>--------------</option>
                                        {originalData.map((c, i) => (
                                          <>
                                            <option value={c._id}>
                                              {c.name}
                                            </option>
                                          </>
                                        ))}
                                      </select>
                                    </div>
                                  </>
                                )}
                              </div>

                              <div
                                className="mb-3 col-md-12"
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "10px",
                                }}
                              >
                                {product.productImages.map((img, i) => (
                                  <>
                                    {" "}
                                    <div
                                      className="mb-3"
                                      style={{
                                        border: "1px solid #DFDFDF",
                                        borderRadius: "10px",
                                        padding: "15px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <img
                                        src={addETrimToUrl(img)}
                                        alt={img}
                                        style={{
                                          height: "auto",
                                          maxHeight: "150px",
                                          maxWidth: "150px",
                                          width: "auto",
                                        }}
                                      />
                                    </div>
                                  </>
                                ))}
                              </div>

                              <div className="mb-3 col-md-12">
                                <label className="form-label">
                                  Product Pdf
                                  <span className="red">*</span>
                                </label>
                                <input
                                  type="file"
                                  className="form-control phone-mask"
                                  name="detail"
                                  accept="application/pdf"
                                  required
                                  onChange={handlePdf}
                                />
                              </div>
                            </div>

                            <br />
                            <hr />
                            <br />
                            <h2>Additional Data Fields</h2>
                            <div className="row">
                              <div className="mb-4 col-md-6">
                                <label className="form-label">
                                  Product Category
                                  <span className="red">*</span>
                                </label>

                                <input
                                  type="text"
                                  className="form-control"
                                  id="basic-default-fullname"
                                  placeholder="Enter Data Here"
                                  name="p1"
                                  defaultValue={product.additionalData?.p1}
                                  required
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="mb-4 col-md-6">
                                <label className="form-label">
                                  Product Category
                                  <span className="red">*</span>
                                </label>

                                <input
                                  type="text"
                                  className="form-control"
                                  id="basic-default-fullname"
                                  placeholder="Enter Data here"
                                  name="p2"
                                  value={product.additionalData?.p2}
                                  required
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="mb-4 col-md-6">
                                <label className="form-label">
                                  Product Category
                                  <span className="red">*</span>
                                </label>

                                <input
                                  type="text"
                                  className="form-control"
                                  id="basic-default-fullname"
                                  placeholder="Enter Data here"
                                  name="p3"
                                  value={product.additionalData?.p3}
                                  required
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="mb-4 col-md-6">
                                <label className="form-label">
                                  Product Category
                                  <span className="red">*</span>
                                </label>

                                <input
                                  type="text"
                                  className="form-control"
                                  id="basic-default-fullname"
                                  placeholder="Enter Data here"
                                  name="p4"
                                  value={product.additionalData?.p4}
                                  required
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="mb-4 col-md-6">
                                <label className="form-label">
                                  Product Category
                                  <span className="red">*</span>
                                </label>

                                <input
                                  type="text"
                                  className="form-control"
                                  id="basic-default-fullname"
                                  placeholder="Enter Data here"
                                  name="p5"
                                  value={product.additionalData?.p5}
                                  required
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="mb-4 col-md-6">
                                <label className="form-label">
                                  Product Category
                                  <span className="red">*</span>
                                </label>

                                <input
                                  type="text"
                                  className="form-control"
                                  id="basic-default-fullname"
                                  placeholder="Enter Data here"
                                  name="p6"
                                  value={product.additionalData?.p6}
                                  required
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={handleSubmit}
                            >
                              {loading ? (
                                <>
                                  <div class="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                </>
                              ) : (
                                <>Submit</>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default UpdateSingleProduct;

import axios from "axios";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Products.css";
import Header from "../../shared/Header";
import Nav from "../../shared/Nav";
import { useGetProductsHook } from "../../shared/API";

const ViewProduct = () => {
  const { isLoading, data } = useGetProductsHook();

  const addETrimToUrl = (url) => {
    // Define the pattern to match
    const pattern = /(upload\/)(v\d+)/;

    // Replace the pattern with 'upload/e_trim/v169...'
    const modifiedUrl = url.replace(pattern, "upload/e_trim/$2");

    return modifiedUrl;
  };

  const deleteProduct = (id) => {
    if (
      window.confirm("Are you sure you want to delete this product?") === true
    ) {
      axios
        .get(
          "https://nj-automations-api.vercel.app/api/admin/deleteProduct/" + id
        )
        .then(() => {
          toast.success("Product Deleted Successfully", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((err) => {
          toast.error("Internal Server Error", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        });
    }
  };

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Nav />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                {isLoading ? (
                  <>
                    <div class="lds-ring purple">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </>
                ) : (
                  <>
                    <h4 className="fw-bold py-3 mb-4">
                      <span className="text-muted fw-light">
                        NJ Automations /
                      </span>{" "}
                      Products
                    </h4>

                    <div className="row mb-5">
                      {data.length === 0 ? (
                        <>
                          <h1>No Products have been Added yet</h1>
                        </>
                      ) : (
                        <>
                          {" "}
                          {data.map((p, i) => (
                            <>
                              <div className="col-md-4 col-lg-4 mb-4">
                                <div className="card h-80">
                                  <div className="card-body">
                                    <img
                                      src={addETrimToUrl(p.productImages[0])}
                                      alt="items"
                                      style={{
                                        width: "100%",
                                        maxHeight: "200px",
                                        maxWidth: "100%",
                                        objectFit: "contain",
                                      }}
                                    />
                                    <br />
                                    <br />
                                    <br />
                                    <h5
                                      className="card-title"
                                      style={{
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: "2",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {p.name}
                                    </h5>
                                    <h6 className="card-title">
                                      <del>Rs {p.price} </del>
                                      <b> &nbsp; Rs {p.disc_price} /-</b>
                                    </h6>
                                    <div className="row justify-content-between align-items-center">
                                      <a
                                        className="col-3"
                                        href="#/"
                                        onClick={() => {
                                          deleteProduct(p._id);
                                        }}
                                      >
                                        <button className="btn btn-danger">
                                          Delete
                                        </button>
                                      </a>
                                      <a
                                        className="col-3 cursor-pointer"
                                        href={"/edit/" + p._id}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width={32}
                                          height={32}
                                        >
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z" />
                                        </svg>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ViewProduct;

import React, { useEffect, useState } from "react";
import Header from "../../shared/Header";
import Nav from "../../shared/Nav";
import { useGetProductsInCategoryHook } from "../../shared/API";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import axios from "axios";

const ViewProductsInCategory = () => {
  const [input, setInput] = useState("");

  const params = useParams();

  const { isLoading, data: ProductsData } = useGetProductsInCategoryHook(
    params.id
  );

  const [filteredData, setFilteredData] = useState([]);

  const [productId, setProductId] = useState();

  useEffect(() => {
    if (ProductsData) {
      setFilteredData(ProductsData);
    }
  }, [ProductsData]);

  const handleSearch = (e) => {
    e.preventDefault();
    const searchValue = e.target.value;
    setInput(searchValue);

    if (searchValue.length > 0) {
      const lowerInput = searchValue.toLowerCase();
      setFilteredData(
        ProductsData.filter((product) =>
          product.name.toLowerCase().includes(lowerInput)
        )
      );
    } else {
      setFilteredData(ProductsData);
    }
  };

  const deleteProductFromCategory = (id) => {
    setProductId(id);
    axios
      .get(
        "https://nj-automations-api.vercel.app/api/admin/deleteProductFromCategory/" +
          id
      )
      .then((res) => {
        setProductId(null);
        toast.success("Product Removed From Category");
        setFilteredData(filteredData.filter((product) => product._id !== id));
      })
      .catch((err) => {
        setProductId(null);
        toast.error("Internal Server Error");
      });
  };

  const addETrimToUrl = (url) => {
    // Define the pattern to match
    const pattern = /(upload\/)(v\d+)/;

    // Replace the pattern with 'upload/e_trim/v169...'
    const modifiedUrl = url.replace(pattern, "upload/e_trim/$2");

    return modifiedUrl;
  };

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Nav />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                {isLoading ? (
                  <>
                    <div class="lds-ring purple">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </>
                ) : (
                  <>
                    <h4 className="fw-bold py-3 mb-4">
                      <span className="text-muted fw-light">
                        NJ Automations /
                      </span>{" "}
                      Products in Category
                    </h4>

                    <div className="navbar-nav align-items-center">
                      <div className="nav-item d-flex align-items-center w-100">
                        <i className="bx bx-search fs-4 lh-0" />
                        <input
                          type="text"
                          className="form-control border-0 shadow-none"
                          placeholder="Search For Products By Their Name"
                          aria-label="Search..."
                          onChange={handleSearch}
                        />
                      </div>
                    </div>

                    <br />
                    <div className="row mb-5">
                      {filteredData.length === 0 ? (
                        <>
                          <h1>No Product Found</h1>
                        </>
                      ) : (
                        <>
                          {filteredData.map((p, i) => (
                            <>
                              <div
                                className="col-md-4 col-lg-4 mb-4"
                                id={p._id}
                              >
                                <div className="card h-80">
                                  <div className="card-body">
                                    <img
                                      src={addETrimToUrl(p.productImages[0])}
                                      alt="items"
                                      style={{
                                        width: "100%",
                                        maxHeight: "200px",
                                        maxWidth: "100%",
                                        objectFit: "contain",
                                      }}
                                    />
                                    <br />
                                    <br />
                                    <br />
                                    <h5
                                      className="card-title"
                                      style={{
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: "2",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {p.name}
                                    </h5>
                                    <h6 className="card-title">
                                      <del>Rs {p.price} </del>
                                      <b> &nbsp; Rs {p.disc_price} /-</b>
                                    </h6>
                                    <div className="row justify-content-between align-items-center">
                                      <a
                                        className="col-8"
                                        href="#/"
                                        onClick={() => {
                                          deleteProductFromCategory(p._id);
                                        }}
                                      >
                                        <button className="btn btn-danger">
                                          {productId === p._id ? (
                                            <>
                                              {" "}
                                              <div class="lds-ring white">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                              </div>
                                            </>
                                          ) : (
                                            <>Delete From Category</>
                                          )}
                                        </button>
                                      </a>
                                      <a
                                        className="col-3 cursor-pointer"
                                        href={"/edit/" + p._id}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width={32}
                                          height={32}
                                        >
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z" />
                                        </svg>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ViewProductsInCategory;

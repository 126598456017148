import React, { useState } from "react";
import Header from "../../shared/Header";
import Nav from "../../shared/Nav";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useGetSingleOrderHook } from "../../shared/API";

const SingleOrder = () => {
  const [updating, setUpdating] = useState(false);

  const [showTrackingId, setShowTrackingId] = useState(false);

  const params = useParams();

  const { isLoading, data } = useGetSingleOrderHook(params.id);

  console.log(data);

  const status = ["Ordered", "Prepairing", "Dispatched", "Delivered"];

  let [trackingId, setTrackingId] = useState("");

  let [orderStatus, setOrderStatus] = useState("");

  const addETrimToUrl = (url) => {
    // Define the pattern to match
    const pattern = /(upload\/)(v\d+)/;

    // Replace the pattern with 'upload/e_trim/v169...'
    const modifiedUrl = url.replace(pattern, "upload/e_trim/$2");

    return modifiedUrl;
  };

  const handleChange = (e) => {
    if (e.target.value === "Dispatched") {
      setShowTrackingId(true);
      setOrderStatus(e.target.value);
    } else {
      setShowTrackingId(false);
      setOrderStatus(e.target.value);
    }
  };

  const handleUpdateStatus = (id) => {
    setUpdating(true);
    data.tracking_id = trackingId;
    if (orderStatus !== "Dispatched") {
      axios
        .get(
          "https://nj-automations-api.vercel.app/api/admin/updateOrderStatus/" +
            id +
            "/" +
            orderStatus
        )
        .then((res) => {
          if (res.status === 200) {
            setUpdating(false);
            toast.success(
              "Order Updated Successfully, Redirecting in 3 Seconds"
            );
            setInterval(() => {
              window.location.href = "/Orders";
            }, 3000);
          }
        })
        .catch((err) => {
          setUpdating(false);
          toast.error("Internal Server Error", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        });
    } else {
      if (data.tracking_id === "") {
        toast.error("Empty Tracking Link, Please Provide Tracking Link");
      } else {
        axios
          .post(
            "https://nj-automations-api.vercel.app/api/admin/updateOrderStatus/" +
              id +
              "/" +
              orderStatus,
            { tracking: trackingId }
          )
          .then((res) => {
            if (res.status === 200) {
              setUpdating(false);
              toast.success(
                "Order Updated Successfully, Redirecting in 3 Seconds"
              );
              setInterval(() => {
                window.location.href = "/Orders";
              }, 3000);
            }
          })
          .catch((err) => {
            setUpdating(false);
            toast.error("Internal Server Error", {
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          });
      }
    }
  };

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Nav />

            <div className="container-xxl flex-grow-1 container-p-y">
              <h4 className="fw-bold py-3 mb-4">
                <span className="text-muted fw-light"> NJ Automations / </span>{" "}
                Order
              </h4>
              {isLoading ? (
                <>
                  <div class="lds-ring purple">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </>
              ) : (
                <>
                  <div className="container">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-12">
                        <div className="card">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="images p-3">
                                <div className="text-center p-4">
                                  {" "}
                                  <img
                                    id="main-image"
                                    src={addETrimToUrl(
                                      data.productDetails[0].productImages[0]
                                    )}
                                    alt={
                                      data.productDetails[0].productImages[0]
                                    }
                                    style={{ maxWidth: "100%" }}
                                  />{" "}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="product p-4 py-0">
                                <div className="mt-4 mb-3">
                                  {" "}
                                  <h5>
                                    =============== Order Info ===============
                                  </h5>
                                  <h6>Order Id: {data._id}</h6>
                                  <h6>Order Status: {data.status}</h6>
                                  <h6>Ordered Quantity: {data.qty}</h6>
                                  <h6>Transaction Id: {data.razorpay_id}</h6>
                                  <h6>Ordered On: {data.createdAt}</h6>
                                  {data.tracking_id !== null ? (
                                    <>
                                      {" "}
                                      <h6>
                                        Track Here:{" "}
                                        <a
                                          href={data.tracking_id}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Tracking Link{" "}
                                        </a>
                                      </h6>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <h5>
                                    =============== Product Info ===============
                                  </h5>
                                  <h6>
                                    Product Id: {data.productDetails[0]._id}
                                  </h6>
                                  <h6>
                                    Product Name: {data.productDetails[0].name}
                                  </h6>
                                  <h6>
                                    Product Price:{" "}
                                    {data.productDetails[0].disc_price}
                                  </h6>
                                  <h5>
                                    =============== Customer Info
                                    ===============
                                  </h5>
                                  <h6>
                                    Customer Name: {data.userDetails[0].name}
                                  </h6>
                                  <h6>
                                    Customer Address: {data.address},{" "}
                                    {data.city}, {data.state}, {data.zip}
                                  </h6>
                                  <h6>
                                    Customer ID: {data.userDetails[0]._id}
                                  </h6>
                                  <h6>
                                    Customer Email: {data.userDetails[0].email}
                                  </h6>
                                  <h6>
                                    Customer Phone Number:{" "}
                                    {data.userDetails[0].phno}
                                  </h6>
                                  <h4>Update Order</h4>
                                  <select
                                    class="form-select"
                                    onChange={handleChange}
                                  >
                                    <option value={data.status} selected>
                                      {data.status}
                                    </option>
                                    {status.map((s, i) => (
                                      <>
                                        {s === data.status ? (
                                          <></>
                                        ) : (
                                          <>
                                            <option value={s}>{s}</option>
                                          </>
                                        )}
                                      </>
                                    ))}
                                  </select>
                                  <br />
                                  {showTrackingId ? (
                                    <>
                                      <div class="mb-3">
                                        <label
                                          class="form-label"
                                          for="basic-default-fullname"
                                        >
                                          Tracking:
                                        </label>
                                        <input
                                          className="form-control"
                                          type="text"
                                          name="trackingId"
                                          id="trackingId"
                                          onChange={(e) => {
                                            setTrackingId(e.target.value);
                                          }}
                                          defaultValue={data.tracking_id}
                                          placeholder="Enter Tracking Link"
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {updating ? (
                                    <>
                                      <div class="lds-ring purple">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                          handleUpdateStatus(data._id);
                                        }}
                                      >
                                        Update Order Status
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SingleOrder;
